<template>
  <div class="view">
    <CommonHeader
      :back="onBack"
      :title="$route.query.title || ''"/>
    <div class="html-box add-html-box">
      <RichText :html="html" />
    </div>
  </div>
</template>
<script>
import CommonHeader from '../../../components/CommonHeader'
import { mapState } from 'vuex'
import RichText from '../../../components/RichText'
export default {
  data () {
    return {
    }
  },
  components: {
    CommonHeader,
    RichText
  },
  created () {
  },
  watch: {
  },
  computed: {
    ...mapState('task', {
      taskList: state => state.taskList,
      resultList: state => state.resultList,
    }),
    html () {
      const { id } = this.$route.query
      if(this.taskList.length === 0) {
        for (let obj of this.resultList) {
          if (obj.frontend_id === id) {
            return obj.question.html
          }
        }
      } else if (this.taskList.length > 0) {
        for (let obj of this.taskList) {
          for (let obj2 of obj) {
            if (obj2.frontend_id === id) {
              return obj2.question.html
            }
          }
        }
      }

      return null
    }
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    },
  }
}
</script>
<style>
  .add-html-box{
    & img {
      max-width: 100%;
    }
  }
</style>
<style scoped>

@import '../../../assets/quill/quill.snow.css';
  .view{
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    height: 100%;
  }
  .html-box{
    padding: 20px 18px;
    background: #fff;
    flex: 1;
    overflow: auto;

  }
</style>
