<template>
  <div class="ql-snow ql-container">
    <div
      class="ql-editor ql-bubble"
      v-html="html"/>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
    html: {
      default: '',
      type: String
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
<style>

</style>
<style scoped>
@import '../assets/quill/quill.snow.css';
.ql-editor{
  font-size: 16px;
}
</style>
